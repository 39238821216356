import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "../../components/Layout/Sidebar";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Order from "../../../domain/models/order";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import OrderService from "../../../domain/services/OrderService";
import { formatToDate } from "../../utils/formatters/date";
import { format } from "date-fns";
import Chip from "@material-ui/core/Chip";
import Visibility from "@material-ui/icons/Visibility";
import Check from "@material-ui/icons/Check";
import LocalShipping from "@material-ui/icons/LocalShipping";
import ViewOrderModal from "./ViewOrderModal";
import Swal from "sweetalert2";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [activeOrder, setActiveOrder] = useState<Order>();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const orderService = useMemo(() => new OrderService(), []);

  useEffect(() => {
    orderService.getAll().then((data) => {
      setOrders(data);
      setIsLoading(false);
    });
  }, [orderService]);

  const handleToggleModal = (order?: Order) => {
    if (isModalOpen) {
      setActiveOrder(undefined);
    } else {
      setActiveOrder(order);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleToggleConfirmDialog = (order?: Order) => {
    if (isConfirmDialogOpen) {
      setActiveOrder(undefined);
    } else {
      setActiveOrder(order);
    }

    setIsConfirmDialogOpen(!isConfirmDialogOpen);
  };

  const handleConfirmOrder = (id?: string) => {
    if (id) {
      orderService
        .confirm(id)
        .then(async () => {
          setIsConfirmDialogOpen(false);
          await Swal.fire("", `Pedido confirmado com sucesso!`, "success");

          setIsLoading(true);
          orderService.getAll().then((data) => {
            setOrders(data);
            setIsLoading(false);
          });
        })
        .catch(async (err) => {
          setIsConfirmDialogOpen(false);
          console.error(err);
          await Swal.fire(
            "Oops",
            `Não foi possível confirmar o pedido no momento, por favor tente novamente.`,
            "error"
          );
        });
    }
  };

  const handleToggleShippingDialog = (order?: Order) => {
    if (isShippingDialogOpen) {
      setActiveOrder(undefined);
    } else {
      setActiveOrder(order);
    }

    setIsShippingDialogOpen(!isShippingDialogOpen);
  };

  const handleConfirmShipping = (id?: string) => {
    if (id) {
      orderService
        .deliver(id)
        .then(async () => {
          setIsShippingDialogOpen(false);
          await Swal.fire(
            "",
            `Envio do pedido confirmado com sucesso!`,
            "success"
          );

          setIsLoading(true);
          orderService.getAll().then((data) => {
            setOrders(data);
            setIsLoading(false);
          });
        })
        .catch(async (err) => {
          setIsShippingDialogOpen(false);
          console.error(err);
          await Swal.fire(
            "Oops",
            `Não foi possível confirmar o envio do pedido no momento, por favor tente novamente.`,
            "error"
          );
        });
    }
  };

  return (
    <>
      <Sidebar />
      <Header title="Pedidos" />

      {activeOrder && (
        <ViewOrderModal
          data={activeOrder!}
          open={isModalOpen}
          onClose={handleToggleModal}
        />
      )}

      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => handleToggleConfirmDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar pedido</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja confirmar o recebimento deste pedido?
            Após confirmado o usuário será notificado e a confirmação não poderá
            ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => handleToggleConfirmDialog()}
          >
            Cancelar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => handleConfirmOrder(activeOrder?.id)}
            autoFocus
          >
            Confirmar pedido
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isShippingDialogOpen}
        onClose={() => handleToggleShippingDialog()}
        aria-labelledby="ship-dialog-title"
        aria-describedby="ship-dialog-description"
      >
        <DialogTitle id="ship-dialog-title">
          Confirmar envio do pedido
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="ship-dialog-description">
            Você tem certeza que deseja marcar este pedido como enviado? Após
            confirmado o usuário será notificado e a confirmação não poderá ser
            desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => handleToggleShippingDialog()}
          >
            Cancelar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => handleConfirmShipping(activeOrder?.id)}
            autoFocus
          >
            Confirmar envio
          </Button>
        </DialogActions>
      </Dialog>

      <Main>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>Endereço</TableCell>
                <TableCell align="right">Confirmado</TableCell>
                <TableCell align="right">Enviado</TableCell>
                <TableCell align="right">Criado em</TableCell>
                <TableCell align="right">Atualizado em</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    align="center"
                    sx={{ height: 100 }}
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                orders?.map((order, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      height: 50,
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {order.product?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.user?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${order.address?.street}, ${order.address?.number}, ${order.address?.city}, ${order.address?.state}`}
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        label={order.confirmed ? "SIM" : "NÃO"}
                        size="medium"
                        color={order.confirmed ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        label={order.status === "transit" ? "SIM" : "NÃO"}
                        size="medium"
                        color={order.status === "transit" ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {format(
                        formatToDate(order.createdAt),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {order.updatedAt
                        ? format(
                            formatToDate(order.updatedAt),
                            "dd/MM/yyyy 'às' HH:mm"
                          )
                        : "--"}
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup>
                        <IconButton onClick={() => handleToggleModal(order)}>
                          <Visibility sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleToggleConfirmDialog(order)}
                        >
                          <Check sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleToggleShippingDialog(order)}
                        >
                          <LocalShipping
                            sx={{ fontSize: 20, color: "#ffffff" }}
                          />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Main>
    </>
  );
};

export default Orders;
