class UserNotFoundError extends Error {
  public static USER_NOT_FOUND: string = "User not found in the base";

  constructor() {
    super();
    this.name = "UserNotFoundError";
    this.stack = (new Error() as any).stack;
  }
}

export default UserNotFoundError;
