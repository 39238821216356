import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";

import SignIn from "./application/pages/SignIn";
import Dashboard from "./application/pages/Dashboard";
import Surveys from "./application/pages/Surveys";
import Suggestions from "./application/pages/Suggestions";
import Categories from "./application/pages/Categories";
import Adverts from "./application/pages/Adverts";
import Store from "./application/pages/Store";
import Orders from "./application/pages/Orders";

const Routes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" component={SignIn} exact />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/surveys" component={Surveys} />
        <Route path="/suggestions" component={Suggestions} />
        <Route path="/categories" component={Categories} />
        <Route path="/adverts" component={Adverts} />
        <Route path="/store" component={Store} />
        <Route path="/orders" component={Orders} />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
