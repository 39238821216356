import {
  collection,
  getDocs,
  getDoc,
  setDoc,
  doc,
  query,
  addDoc,
  deleteDoc,
} from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import Product from "../models/product";
import { store, storage } from "./firebase";

class ProductService {
  async getAll(): Promise<Product[] | undefined> {
    const productRef = collection(store, "products");

    const q = query(productRef);

    const querySnapshot = await getDocs(q);
    const products = querySnapshot.docs.map((doc) => {
      const id = doc.id;
      const product = doc.data() as Product;

      return { ...product, id };
    });

    return products;
  }

  async create(files: File[], product: Product): Promise<void> {
    let pictures: string[] = [];

    // Upload the pictures
    await Promise.all(
      files.map(async (file) => {
        const reference = ref(storage, `images/products/product_${file.name}`);
        const uploadTask = await uploadBytes(reference, file);

        const url = await getDownloadURL(uploadTask.ref);
        console.log("URL", url);
        pictures = [...pictures, url];
        console.log(pictures);
      })
    );

    // Set the first picture as main
    product = {
      ...product,
      price: parseInt(product.price.toString()),
      mainPicture: pictures[0],
      pictures: pictures.slice(1).length > 0 ? pictures.slice(1) : null,
    };

    console.log(product);

    // Save product to collection
    await addDoc(collection(store, "products"), product).catch((err) => {
      throw new Error(err);
    });
  }

  async update(id: string, files: File[], product: Product): Promise<void> {
    const docRef = doc(store, "products", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const productData = docSnap.data() as Product;
      let pictures: string[] = [
        productData.mainPicture,
        ...(productData.pictures ?? []),
      ];

      if (files.length > 0) {
        // Upload the pictures
        await Promise.all(
          files.map(async (file) => {
            const reference = ref(
              storage,
              `images/products/product_${file.name}`
            );
            const uploadTask = await uploadBytes(reference, file);

            const url = await getDownloadURL(uploadTask.ref);
            console.log("URL", url);
            pictures = [...pictures, url];
            console.log(pictures);
          })
        );

        // Set the first picture as main
        product = {
          ...product,
          mainPicture: pictures[0],
          pictures: pictures.slice(1).length > 0 ? pictures.slice(1) : null,
        };
      } else {
        product = {
          ...product,
          mainPicture: productData.mainPicture,
        };
      }

      const productDataMerged = {
        ...productData,
        ...product,
        price: parseInt(product.price.toString()),
        recommended: product.recommended.toString() === "true" ? true : false,
      };

      // Save product to collection
      await setDoc(
        doc(collection(store, "products"), id),
        productDataMerged
      ).catch((err) => {
        throw new Error(err);
      });
    } else {
      throw new Error("Provided product ID was not found");
    }
  }

  async remove(id: string): Promise<void> {
    const productRef = collection(store, "products");
    await deleteDoc(doc(productRef, id!)).catch((err) => {
      throw new Error(err);
    });
  }
}

export default ProductService;
