import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#212121",
      paper: "#212121",
    },
    primary: {
      main: "#5E2B9C",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      color: "#ffffff",
    },
    caption: {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: 16,
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: 16,
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: 12,
    },
    body1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 14,
    },
    h1: {
      fontFamily: "Poppins",
      fontSize: 40,
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: "10px 15px",
          borderRadius: 12,
          fontSize: 14,
          fontFamily: "Poppins",
          textTransform: "none",
        },
        outlined: {
          textTransform: "none",
          padding: "10px 15px",
          borderRadius: 12,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 200,
          backgroundColor: "#262626",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        positionStatic: {
          marginLeft: 200,
          backgroundColor: "#262626",
          width: "calc(100% - 200px)",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          fontSize: 14,
        },
      },
    },
  },
});

export default theme;
