import React, { useContext } from "react";
import Swal from "sweetalert2";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";

import SocialButton from "../../components/Buttons/SocialButton";
import Spacer from "../../components/Spacer";
import AuthService from "../../../domain/services/AuthService";

import bg from "../../../assets/images/welcome-bg.jpg";
import logo from "../../../assets/images/logos/icon.png";
import UserNotFoundError from "../../../domain/errors/UserNotFoundError";

const SignIn: React.FC = () => {
  const history = useHistory();

  const { setUser } = useContext(AuthContext);

  const handleLogIn = async () => {
    try {
      const authService = new AuthService();

      const user = await authService.signInWithGoogle();
      if (user) {
        setUser(user);
        history.push("/dashboard");
      }
    } catch (err) {
      if (err instanceof UserNotFoundError) {
        await Swal.fire(
          "Oops",
          "Seu usuário não tem permissão para acessar este painel.",
          "error"
        );
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box
            sx={{
              height: "100vh",
              backgroundImage: `url(${bg})`,
            }}
          >
            <Box
              sx={{
                flex: 1,
                height: "100vh",
                backgroundColor: "#5E2B9C",
                backgroundSize: "cover",
                opacity: 0.8,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                paddingX={5}
                sx={{
                  flex: 1,
                  alignItems: "flex-start",
                  justifyContent: "center",
                  height: "100vh",
                  zIndex: 9999,
                  maxWidth: 700,
                }}
              >
                <Typography variant="h1">Opine. Sugira. Ganhe!</Typography>
                <Spacer size={10} />
                <Typography variant="caption">
                  Já pensou em concorrer a prêmios incríveis opinando em
                  enquetes dos mais variados temas e assuntos? Com o MyPrefs
                  isso é possível! Corra e baixe agora o app na loja de sua
                  plataforma e comece agora a votar e ganhar pontos para
                  participar de sorteios.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display="flex"
            flexDirection="column"
            paddingX={5}
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <img src={logo} width={60} alt="Logo" />
            <Spacer size={20} />

            <h3>Acessar dashboard</h3>
            <Spacer size={5} />

            <SocialButton onClick={handleLogIn} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
