import { Timestamp } from "@firebase/firestore";
import { format } from "date-fns";

export const formatDate = (timestamp: Timestamp): string => {
  const date = timestamp.toDate();
  const formattedDate = format(date, "dd/MM/yyyy");

  return formattedDate;
};

export const formatToDate = (timestamp: Timestamp): Date => {
  return timestamp.toDate();
};
