const resolvePayment = (paymentStatus: string) => {
  let result: {
    text: string;
    color:
      | "success"
      | "warning"
      | "default"
      | "error"
      | "primary"
      | "secondary"
      | "info"
      | undefined;
  };

  switch (paymentStatus) {
    case "payed":
      result = { text: "pago", color: "success" };
      break;
    case "error":
      result = { text: "erro", color: "error" };
      break;
    default:
      result = { text: "aguardando", color: "warning" };
      break;
  }

  return result;
};

export default resolvePayment;
