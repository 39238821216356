import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  addDoc,
  setDoc,
  doc,
  deleteDoc,
} from "@firebase/firestore";
import Survey from "../models/survey";
import User from "../models/user";
import { store } from "./firebase";

class SurveyService {
  async getAll(): Promise<Survey[] | undefined> {
    const surveysRef = collection(store, "surveys");

    const q = query(surveysRef, where("suggested", "==", false));

    const querySnapshot = await getDocs(q);
    const surveys = querySnapshot.docs.map((doc) => {
      const survey = doc.data() as Survey;
      return {
        id: doc.id,
        ...survey,
      };
    });

    return surveys;
  }

  async getAllSuggested(): Promise<Survey[] | undefined> {
    const surveysRef = collection(store, "surveys");

    const q = query(
      surveysRef,
      where("suggested", "==", true)
      // where("sponsored", "==", false)
    );

    const querySnapshot = await getDocs(q);
    const surveys = querySnapshot.docs.map((doc) => {
      const survey = doc.data() as Survey;
      return {
        id: doc.id,
        ...survey,
      };
    });

    return surveys;
  }

  async create(survey: Survey): Promise<void> {
    delete survey.id;
    await addDoc(collection(store, "surveys"), survey).catch((err) => {
      throw new Error(err);
    });
  }

  async update(survey: Survey): Promise<void> {
    const surveysRef = collection(store, "surveys");
    const { id } = survey;
    delete survey.id;

    await setDoc(doc(surveysRef, id!), survey).catch((err) => {
      throw new Error(err);
    });
  }

  async approve(id: string, suggestedBy?: string): Promise<void> {
    const surveysRef = collection(store, "surveys");
    const docRef = doc(store, "surveys", id!);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const surveyData = docSnap.data() as Survey;
      await setDoc(doc(surveysRef, id!), {
        ...surveyData,
        suggested: false,
      }).catch((err) => {
        throw new Error(err);
      });

      if (!!suggestedBy) {
        const userRef = doc(store, "users", suggestedBy);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data() as User;
          let extraPoints = 0;

          if (surveyData.scope === "nacional") extraPoints = 3;
          if (surveyData.scope === "estadual") extraPoints = 5;
          if (surveyData.scope === "municipal") extraPoints = 10;

          await setDoc(doc(userRef, suggestedBy!), {
            amount: userData.amount + extraPoints,
          }).catch((err) => {
            throw new Error(err);
          });
        }
      }
    } else {
      throw new Error("Provided survey ID was not found");
    }
  }

  async remove(id: string): Promise<void> {
    const surveysRef = collection(store, "surveys");
    await deleteDoc(doc(surveysRef, id!)).catch((err) => {
      throw new Error(err);
    });
  }
}

export default SurveyService;
