import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "@firebase/firestore";
import { store } from "./firebase";
import Order from "../models/order";
import Address from "../models/address";
import Product from "../models/product";
import User from "../models/user";
import { Timestamp } from "firebase/firestore";

class OrderService {
  async getAll(): Promise<Order[]> {
    const productRef = collection(store, "orders");

    const q = query(productRef);

    const querySnapshot = await getDocs(q);
    return await Promise.all(
      querySnapshot.docs.map(async (document) => {
        const id = document.id;
        const order = document.data() as Order;

        const address = (
          await getDoc(doc(store, "addresses", order.addressId))
        ).data() as Address;
        const product = (
          await getDoc(doc(store, "products", order.productId))
        ).data() as Product;
        const user = (
          await getDoc(doc(store, "users", order.userEmail))
        ).data() as User;

        return { ...order, id, address, product, user };
      })
    );
  }

  async confirm(id: string): Promise<void> {
    const orderRef = collection(store, "orders");
    const docRef = doc(store, "orders", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const orderData = docSnap.data() as Order;
      await setDoc(doc(orderRef, id), {
        ...orderData,
        confirmed: true,
        status: "preparing",
        updatedAt: Timestamp.now(),
      }).catch((err) => {
        throw new Error(err);
      });
    } else {
      throw new Error("Provided order ID was not found");
    }
  }

  async deliver(id: string): Promise<void> {
    const orderRef = collection(store, "orders");
    const docRef = doc(store, "orders", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const orderData = docSnap.data() as Order;
      await setDoc(doc(orderRef, id), {
        ...orderData,
        status: "transit",
        updatedAt: Timestamp.now(),
      }).catch((err) => {
        throw new Error(err);
      });
    } else {
      throw new Error("Provided order ID was not found");
    }
  }
}

export default OrderService;
