import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import Spacer from "../../../components/Spacer";
import { formatDate, formatToDate } from "../../../utils/formatters/date";
import { isPast } from "date-fns";
import { Timestamp } from "@firebase/firestore";
import { Advert } from "../../../../domain/models/advert";
import resolvePayment from "../../../utils/resolvers/payment_resolver";

import ExpandMore from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

type ViewAdvertModalProps = {
  data: Advert;
  open: boolean;
  onClose?: () => void;
};

const ViewAdvertModal: React.FC<ViewAdvertModalProps> = ({
  data,
  open = false,
  onClose,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  const checkSurveyIsExpired = (timestamp: Timestamp): boolean => {
    const date = formatToDate(timestamp);
    return isPast(date);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid spacing={2} container>
            <Grid item xs={3}>
              <Typography variant="subtitle2">Tipo</Typography>
              <Chip label={data.type} size="medium" />
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Escopo</Typography>
              <Typography variant="body1">{data.scope}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Cidade</Typography>
              <Typography variant="body1">{data.city || "--"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Estado</Typography>
              <Typography variant="body1">{data.state || "--"}</Typography>
            </Grid>

            {data.type === "survey" && data.survey && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">Enquete</Typography>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>Detalhes</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ maxHeight: 250, overflowY: "auto" }}>
                    <Grid spacing={2} container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Descrição</Typography>
                        <Typography variant="body1">
                          {data.survey?.description}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant="subtitle2">
                          Múltiplas respostas
                        </Typography>
                        <Chip
                          label={data.survey?.hasMultiple ? "Sim" : "Não"}
                          size="medium"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant="subtitle2">
                          Qtd. de respostas
                        </Typography>
                        <Typography variant="body1">
                          {data.survey?.maxCheck}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Opções</Typography>
                        <Box>
                          <List>
                            {data.survey?.options.map((option, i) => (
                              <ListItem key={i}>
                                <ListItemIcon>
                                  <Typography>#{i + 1}</Typography>
                                </ListItemIcon>
                                <Grid spacing={2} container>
                                  <Grid xs={8} item>
                                    <Typography variant="subtitle2">
                                      Descrição
                                    </Typography>
                                    <Typography>
                                      {option.description}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={2} item>
                                    <Typography variant="subtitle2">
                                      Joker
                                    </Typography>
                                    <Chip
                                      label={option.isJoker ? "Sim" : "Não"}
                                      size="medium"
                                    />
                                  </Grid>
                                  <Grid xs={2} item>
                                    <Typography variant="subtitle2">
                                      Votos
                                    </Typography>
                                    <Typography>{option.votes}</Typography>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

            {data.type === "banner" && data.banner && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">Banner</Typography>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>Detalhes</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ maxHeight: 250, overflowY: "auto" }}>
                    <img alt="Banner" />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

            <Grid item xs={4}>
              <Typography variant="subtitle2">Situação</Typography>
              <Chip
                label={data.isActive ? "ativo" : "inativo"}
                color={data.isActive ? "success" : "error"}
                size="medium"
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2">Pagamento</Typography>
              <Chip
                label={resolvePayment(data.paymentStatus).text}
                color={resolvePayment(data.paymentStatus).color}
                size="medium"
              />
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Email do criador</Typography>
              <Typography variant="body1">{data.userEmail}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2">Criada em</Typography>
              <Typography variant="body1">
                {formatDate(data.createdAt)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2">Atualizada em</Typography>
              <Typography variant="body1">
                {formatDate(data.updatedAt)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2">Válida até</Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {formatDate(data.validUntil)}
                <Spacer size={5} horizontal />
                {checkSurveyIsExpired(data.validUntil) && (
                  <Chip label="Expirada" color="error" size="small" />
                )}
              </Typography>
            </Grid>

            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              item
            >
              <Button variant="contained" onClick={handleClose}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ViewAdvertModal;
