import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Order from "../../../../domain/models/order";
import { format } from "date-fns";
import { formatToDate } from "../../../utils/formatters/date";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type ViewOrderModalProps = {
  data: Order;
  open: boolean;
  onClose?: () => void;
};

const ViewOrderModal: React.FC<ViewOrderModalProps> = ({
  data,
  open = false,
  onClose,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  const resolveStatus = (status?: string | null) => {
    if (status === "transit") return "Pedido em trânsito";
    if (status === "preparing") return "Pedido em preparação";
    if (status === "delivered") return "Pedido entregue";

    return "Aguardando confirmação";
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid spacing={2} container>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Usuário</Typography>
              <Typography variant="body1">{data.user?.name ?? "--"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Pedido criado em</Typography>
              <Typography variant="body1">
                {format(formatToDate(data.createdAt), "dd/MM/yyyy 'às' HH:mm")}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Pedido confirmado</Typography>
              <Chip
                label={data.confirmed ? "SIM" : "NÃO"}
                size="small"
                color={data.confirmed ? "success" : "error"}
              />
            </Grid>

            <Grid item xs={10}>
              <Typography variant="subtitle2">Endereço completo</Typography>
              <Typography variant="body1">{`${data.address?.street}, ${data.address?.number}, ${data.address?.district}, ${data.address?.city}, ${data.address?.state}, ${data.address?.cep}`}</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Complemento</Typography>
              <Typography variant="body1">
                {data.address?.complement ?? "--"}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Imagem do produto</Typography>
              <img
                src={data.product?.mainPicture}
                alt="imagem do produto"
                width={100}
              />
            </Grid>

            <Grid item xs={10}>
              <Typography variant="subtitle2">Nome do produto</Typography>
              <Typography variant="body1">{data.product?.name}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Status do pedido</Typography>
              <Typography variant="body1">
                {resolveStatus(data.status)}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Pedido entregue em</Typography>
              <Typography variant="body1">
                {data.deliveredAt
                  ? format(
                      formatToDate(data.deliveredAt),
                      "dd/MM/yyyy 'às' HH:mm"
                    )
                  : "--"}
              </Typography>
            </Grid>

            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              item
            >
              <Button variant="contained" onClick={handleClose}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ViewOrderModal;
