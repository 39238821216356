import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  deleteDoc,
} from "@firebase/firestore";
import Category from "../models/category";
import { store } from "./firebase";

class CategoryService {
  async getAll(): Promise<Category[] | undefined> {
    const categoriesRef = collection(store, "categories");

    const q = query(categoriesRef);

    const querySnapshot = await getDocs(q);
    const categories = querySnapshot.docs.map((doc) => {
      const id = doc.id;
      const category = doc.data() as Category;

      return { ...category, id };
    });

    return categories;
  }

  async create(category: Category): Promise<void> {
    delete category.id;
    await addDoc(collection(store, "categories"), category).catch((err) => {
      throw new Error(err);
    });
  }

  async update(category: Category): Promise<void> {
    const categoriesRef = collection(store, "categories");
    const { id } = category;
    delete category.id;

    await setDoc(doc(categoriesRef, id!), category).catch((err) => {
      throw new Error(err);
    });
  }

  async remove(id: string): Promise<void> {
    const categoriesRef = collection(store, "categories");
    await deleteDoc(doc(categoriesRef, id!)).catch((err) => {
      throw new Error(err);
    });
  }
}

export default CategoryService;
