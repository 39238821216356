import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Spacer from "../../../components/Spacer";
import Category from "../../../../domain/models/category";
import { useFormik } from "formik";

type AddCategoryModalProps = {
  category?: Category;
  open: boolean;
  onClose?: () => void;
  onSubmit: (category: Category, editing: boolean) => void;
};

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
  category,
  open,
  onClose,
  onSubmit,
}) => {
  const formik = useFormik<Category>({
    initialValues: {
      description: category?.description || "",
    },
    onSubmit: (values) => {
      onSubmit?.(
        {
          ...values,
          id: category?.id,
        },
        !!category
      );
    },
  });

  const handleClose = () => {
    onClose?.();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box>
            <Typography variant="h6">
              {category ? "Editar" : "Adicionar"} categoria
            </Typography>
            <Spacer size={20} />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Descrição</Typography>
                <TextField
                  variant="outlined"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  hiddenLabel
                  fullWidth
                />
              </Grid>

              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                item
              >
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancelar
                </Button>
                <Spacer size={10} horizontal />
                <Button variant="contained" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddCategoryModal;
