import React from "react";
import Button from "@material-ui/core/Button";

import googleLogo from "../../../assets/images/icons/google.png";

type SocialButtonProps = {
  onClick?: () => void;
};

const SocialButton: React.FC<SocialButtonProps> = ({ onClick }) => {
  return (
    <>
      <Button variant="contained" onClick={onClick}>
        <img
          src={googleLogo}
          width={20}
          style={{
            marginRight: 10,
          }}
          alt="Social icon"
        />
        Continuar com Google
      </Button>
    </>
  );
};

export default SocialButton;
