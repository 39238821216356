import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import Survey from "../../../../domain/models/survey";
import { formatDate } from "../../../utils/formatters/date";

type ViewSuggestionModalProps = {
  data: Survey;
  open: boolean;
  onClose?: () => void;
};

const ViewSuggestionModal: React.FC<ViewSuggestionModalProps> = ({
  data,
  open = false,
  onClose,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Descrição</Typography>
              <Typography variant="body1">{data.description}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Escopo</Typography>
              <Chip label={data.scope} size="medium" />
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Cidade</Typography>
              <Typography variant="body1">{data.city || "--"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Estado</Typography>
              <Typography variant="body1">{data.state || "--"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Grupo</Typography>
              <Typography variant="body1">{data.group || "--"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Categoria</Typography>
              <Chip label={data.category || "A DEFINIR"} size="medium" />
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Sugerida</Typography>
              <Chip label={data.suggested ? "Sim" : "Não"} size="medium" />
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Múltiplas respostas</Typography>
              <Chip label={data.suggested ? "Sim" : "Não"} size="medium" />
            </Grid>

            <Grid item xs={3}>
              <Typography variant="subtitle2">Qtd. de respostas</Typography>
              <Typography variant="body1">{data.maxCheck}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2">Opções</Typography>
              <Box>
                <List>
                  {data.options.map((option, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <Typography>#{i + 1}</Typography>
                      </ListItemIcon>
                      <Grid spacing={2} container>
                        <Grid xs={8} item>
                          <Typography variant="subtitle2">Descrição</Typography>
                          <Typography>{option.description}</Typography>
                        </Grid>
                        <Grid xs={2} item>
                          <Typography variant="subtitle2">Joker</Typography>
                          <Chip
                            label={option.isJoker ? "Sim" : "Não"}
                            size="medium"
                          />
                        </Grid>
                        <Grid xs={2} item>
                          <Typography variant="subtitle2">Votos</Typography>
                          <Typography>{option.votes}</Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Criada em</Typography>
              <Typography variant="body1">
                {formatDate(data.createdAt)}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Atualizada em</Typography>
              <Typography variant="body1">
                {formatDate(data.updatedAt)}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Válida até</Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {formatDate(data.validUntil)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2">Saiba mais</Typography>
              <Typography variant="body1">{data.knowMore || "--"}</Typography>
            </Grid>

            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              item
            >
              <Button variant="contained" onClick={handleClose}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ViewSuggestionModal;
