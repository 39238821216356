import React, { useEffect, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Product from "../../../domain/models/product";
import ProductService from "../../../domain/services/ProductService";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import Spacer from "../../components/Spacer";

import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import ProductFormModal from "./ProductFormModal";

import Swal from "sweetalert2";
import ViewProductModal from "./ViewProductModal";

const Store: React.FC = () => {
  const [products, setProducts] = useState<Product[]>();
  const [activeProduct, setActiveProduct] = useState<Product>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const productService = useMemo(() => new ProductService(), []);

  useEffect(() => {
    productService.getAll().then((data) => {
      setProducts(data);
      setIsLoading(false);
    });
  }, [productService]);

  const handleToggleModal = (product?: Product) => {
    if (isModalOpen) {
      setActiveProduct(undefined);
    } else {
      setActiveProduct(product);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleToggleFormModal = (product?: Product) => {
    if (isFormModalOpen) {
      setActiveProduct(undefined);
    } else {
      setActiveProduct(product);
    }

    setIsFormModalOpen(!isFormModalOpen);
  };

  const handleOnSubmit = (
    product: Product,
    files: File[],
    editing: boolean
  ) => {
    let statement: Promise<void>;

    if (editing) {
      statement = productService.update(activeProduct!.id!, files, product);
    } else {
      statement = productService.create(files, product);
    }

    statement
      .then(async () => {
        setIsFormModalOpen(false);
        await Swal.fire(
          "",
          `Produto ${editing ? "editado" : "criado"} com sucesso.`,
          "success"
        );

        setIsLoading(true);
        productService.getAll().then((data) => {
          setProducts(data);
          setIsLoading(false);
        });
      })
      .catch(async (err) => {
        setIsFormModalOpen(false);
        console.error(err);
        await Swal.fire(
          "Oops",
          `Não foi possível ${
            editing ? "editar" : "criar"
          } o produto, por favor tente novamente.`,
          "error"
        );
      });
  };

  const handleToggleDialog = (product?: Product) => {
    if (isDialogOpen) {
      setActiveProduct(undefined);
    } else {
      setActiveProduct(product);
    }

    setIsDialogOpen(!isDialogOpen);
  };

  const handleRemoveProduct = () => {
    if (activeProduct?.id) {
      productService
        .remove(activeProduct!.id!)
        .then(async () => {
          setIsDialogOpen(false);
          await Swal.fire("", `Produto excluído com sucesso.`, "success");

          setIsLoading(true);
          productService.getAll().then((data) => {
            setProducts(data);
            setIsLoading(false);
          });
        })
        .catch(async (err) => {
          setIsDialogOpen(false);
          console.error(err);
          await Swal.fire(
            "Oops",
            `Não foi possível excluir o produto no momento, por favor tente novamente.`,
            "error"
          );
        });
    }
  };

  return (
    <>
      <Sidebar />
      <Header title="Loja" />

      {isFormModalOpen && (
        <ProductFormModal
          open={isFormModalOpen}
          onClose={handleToggleFormModal}
          onSubmit={handleOnSubmit}
          product={activeProduct}
        />
      )}

      {activeProduct && (
        <ViewProductModal
          data={activeProduct!}
          open={isModalOpen}
          onClose={handleToggleModal}
        />
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => handleToggleDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Excluir produto</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja excluir o produto selecionado? Ao
            excluir os dados não poderão ser recuperados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "#fff" }} onClick={() => handleToggleDialog()}>
            Cancelar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={handleRemoveProduct}
            autoFocus
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Main>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button variant="contained" onClick={() => handleToggleFormModal()}>
            <Add />
            Novo Produto
          </Button>
        </Box>
        <Spacer size={10} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Preço</TableCell>
                <TableCell align="right">Recomendado</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    align="center"
                    sx={{ height: 100 }}
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                products &&
                products.map((product, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      height: 50,
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {product.name}
                    </TableCell>
                    <TableCell align="right">{`PREF$ ${product.price}`}</TableCell>
                    <TableCell align="right">
                      <Chip
                        label={product.recommended ? "SIM" : "NÃO"}
                        size="medium"
                        color={product.recommended ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup>
                        <IconButton onClick={() => handleToggleModal(product)}>
                          <Visibility sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleToggleFormModal(product)}
                        >
                          <Edit sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton onClick={() => handleToggleDialog(product)}>
                          <Delete sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Main>
    </>
  );
};

export default Store;
