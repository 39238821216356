const firebaseConfig = {
  apiKey: "AIzaSyBpUpeFTEdnzQh4aFDW-x0uSksrIn8pGlM",
  authDomain: "my-prefs.firebaseapp.com",
  projectId: "my-prefs",
  storageBucket: "my-prefs.appspot.com",
  messagingSenderId: "963167934206",
  appId: "1:963167934206:web:3d37f4c1afae471e4c37af",
  measurementId: "G-X5D9EGB951",
};

export default firebaseConfig;
