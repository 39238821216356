import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, store } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

import Admin from "../models/admin";
import UserNotFoundError from "../errors/UserNotFoundError";
import axios from "axios";

export interface UsersResponse {
  users: {
    metadata: {
      creationTime: string;
    };
  }[];
}

class AuthService {
  async signInWithGoogle(): Promise<Admin | undefined> {
    const response = await signInWithPopup(auth, new GoogleAuthProvider());

    if (response.user) {
      const { email } = response.user;
      const docRef = doc(store, "admins", email!);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        return snapshot.data() as Admin;
      } else {
        throw new UserNotFoundError();
      }
    }
  }

  async getUsers(): Promise<UsersResponse> {
    try {
      const users = await axios.get<UsersResponse>(
        "https://us-central1-my-prefs.cloudfunctions.net/getUsers"
      );

      return users.data;
    } catch (err) {
      throw err;
    }
  }
}

export default AuthService;
