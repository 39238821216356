import React, { useEffect, useMemo, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import Button from "@material-ui/core/Button";

import Spacer from "../../components/Spacer";
import CategoryService from "../../../domain/services/CategoryService";
import Category from "../../../domain/models/category";

import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import AddCategoryModal from "./AddCategoryModal";
import Swal from "sweetalert2";

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>();
  const [activeCategory, setActiveCategory] = useState<Category>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const categoryService = useMemo(() => new CategoryService(), []);

  useEffect(() => {
    categoryService.getAll().then((data) => {
      setCategories(data);
      setIsLoading(false);
    });
  }, [categoryService]);

  const handleToggleAddModal = (category?: Category) => {
    if (isModalOpen) {
      setActiveCategory(undefined);
    } else {
      setActiveCategory(category);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = (category: Category, editing: boolean) => {
    let statement: Promise<void>;

    if (!editing) {
      statement = categoryService.create(category);
    } else {
      statement = categoryService.update(category);
    }

    statement
      .then(async () => {
        setIsModalOpen(false);
        await Swal.fire(
          "",
          `Categoria ${editing ? "editada" : "criada"} com sucesso.`,
          "success"
        );

        setIsLoading(true);
        categoryService.getAll().then((data) => {
          setCategories(data);
          setIsLoading(false);
        });
      })
      .catch(async (err) => {
        setIsModalOpen(false);
        console.error(err);
        await Swal.fire(
          "Oops",
          `Não foi possível ${
            editing ? "editar" : "criar"
          } a categoria, por favor tente novamente.`,
          "error"
        );
      });
  };

  const handleToggleDialog = (category?: Category) => {
    if (isDialogOpen) {
      setActiveCategory(undefined);
    } else {
      setActiveCategory(category);
    }

    setIsDialogOpen(!isDialogOpen);
  };

  const handleRemoveCategory = () => {
    categoryService
      .remove(activeCategory!.id!)
      .then(async () => {
        setIsDialogOpen(false);
        await Swal.fire("", `Categoria excluída com sucesso.`, "success");

        setIsLoading(true);
        categoryService.getAll().then((data) => {
          setCategories(data);
          setIsLoading(false);
        });
      })
      .catch(async (err) => {
        setIsDialogOpen(false);
        console.error(err);
        await Swal.fire(
          "Oops",
          `Não foi possível excluir a categoria, por favor tente novamente.`,
          "error"
        );
      });
  };

  return (
    <>
      <Sidebar />
      <Header title="Categorias" />

      {isModalOpen && (
        <AddCategoryModal
          open={isModalOpen}
          onClose={handleToggleAddModal}
          category={activeCategory}
          onSubmit={handleSubmit}
        />
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => handleToggleDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Excluir categoria</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja excluir a categoria selecionada? Ao
            excluir os dados não poderão ser recuperados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "#fff" }} onClick={() => handleToggleDialog()}>
            Cancelar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={handleRemoveCategory}
            autoFocus
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Main>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button variant="contained" onClick={() => handleToggleAddModal()}>
            <Add />
            Nova Categoria
          </Button>
        </Box>
        <Spacer size={10} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">Categoria</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    align="center"
                    sx={{ height: 100 }}
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                categories &&
                categories.map((category, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      height: 50,
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Chip label={category.description} size="medium" />
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup>
                        <IconButton
                          onClick={() => handleToggleAddModal(category)}
                        >
                          <Edit sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleToggleDialog(category)}
                        >
                          <Delete sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Main>
    </>
  );
};

export default Categories;
