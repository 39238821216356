import React from "react";

type SpacerProps = {
  size: number;
  horizontal?: boolean;
};

const Spacer: React.FC<SpacerProps> = ({ size, horizontal }) => {
  return (
    <div
      style={{
        height: !horizontal ? size : 0,
        width: horizontal ? size : 0,
      }}
    />
  );
};

export default Spacer;
