import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";

import Survey from "../../../domain/models/survey";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import Spacer from "../../components/Spacer";

import AuthService from "../../../domain/services/AuthService";
import SurveyService from "../../../domain/services/SurveyService";

import { Timestamp } from "@firebase/firestore";
import _, { Dictionary } from "lodash";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { formatDate, formatToDate } from "../../utils/formatters/date";
import { isFuture, isPast } from "date-fns";
import { useHistory } from "react-router";

import Visibility from "@material-ui/icons/Visibility";
import AdvertService from "../../../domain/services/AdvertService";
import { Advert } from "../../../domain/models/advert";

const Dashboard: React.FC = () => {
  const [surveys, setSurveys] = useState<Survey[]>();
  const [topFive, setTopFive] = useState<{ votes: number; survey: Survey }[]>();
  const [surveysPerScope, setSurveysPerScope] = useState<number[]>();
  const [usersPerMonth, setUsersPerMonth] = useState<
    Dictionary<
      [
        {
          metadata: {
            creationTime: string;
          };
        },
        ...{
          metadata: {
            creationTime: string;
          };
        }[]
      ]
    >
  >();
  const [ads, setAds] = useState<Dictionary<[Advert, ...Advert[]]>>();
  const [adsPerType, setAdsPerType] =
    useState<Dictionary<[Advert, ...Advert[]]>>();

  const history = useHistory();

  const authService = useMemo(() => new AuthService(), []);
  const surveyService = useMemo(() => new SurveyService(), []);
  const advertService = useMemo(() => new AdvertService(), []);

  const checkSurveyIsExpired = (timestamp: Timestamp): boolean => {
    const date = formatToDate(timestamp);
    return isPast(date);
  };

  const calculateTotal = (survey: Survey): number => {
    const total = survey.options.reduce((a, b) => {
      return a + b.votes;
    }, 0);

    return total;
  };

  const getMostVotedSurveys = useCallback(async () => {
    const surveys = await surveyService.getAll();
    if (surveys) {
      const votes = surveys.map((survey) => {
        const votes = calculateTotal(survey);
        return {
          votes,
          survey: survey,
        };
      });

      const sorted = votes.sort((a, b) => {
        if (a.votes < b.votes) return 1;
        if (a.votes > b.votes) return -1;
        return 0;
      });

      const topFive = sorted.splice(0, 5);

      return topFive;
    }
  }, [surveyService]);

  const countSurveysPerScope = useCallback(async () => {
    const surveys = await surveyService.getAll();
    if (surveys) {
      const n = surveys.reduce(
        (acc, cur) => (cur.scope === "nacional" ? ++acc : acc),
        0
      );
      const e = surveys.reduce(
        (acc, cur) => (cur.scope === "estadual" ? ++acc : acc),
        0
      );
      const m = surveys.reduce(
        (acc, cur) => (cur.scope === "municipal" ? ++acc : acc),
        0
      );

      return [n, e, m];
    }
  }, [surveyService]);

  const getUsersProgression = useCallback(async () => {
    const { users } = await authService.getUsers();

    const ordered = users.sort((a, b) =>
      new Date(b.metadata.creationTime) < new Date(a.metadata.creationTime)
        ? 1
        : -1
    );
    const grouped = _.groupBy(ordered, (user) => {
      const months = [
        "janeiro",
        "fevereiro",
        "março",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
      ];
      const month = new Date(user.metadata.creationTime).getMonth();
      return months[month];
    });

    return grouped;
  }, [authService]);

  const getExpiredSurveys = useCallback(() => {
    surveyService.getAll().then((data) => {
      if (data) {
        const expired = data.filter((item) =>
          checkSurveyIsExpired(item.validUntil)
        );
        setSurveys(expired);
      }
    });
  }, [surveyService]);

  const getAdvertsAmountPerMonth = useCallback(async () => {
    const adverts = await advertService.getAll();
    if (adverts) {
      const validAdverts = adverts.filter(
        (x) => x.price && x.paymentStatus === "payed"
      );
      const ordered = validAdverts.sort((a, b) =>
        formatToDate(b.createdAt) < formatToDate(a.createdAt) ? 1 : -1
      );

      const grouped = _.groupBy(ordered, (advert) => {
        const months = [
          "janeiro",
          "fevereiro",
          "março",
          "abril",
          "maio",
          "junho",
          "julho",
          "agosto",
          "setembro",
          "outubro",
          "novembro",
          "dezembro",
        ];
        const month = formatToDate(advert.createdAt).getMonth();
        return months[month];
      });

      return grouped;
    }
  }, [advertService]);

  const getAdvertsPerType = useCallback(async () => {
    const adverts = await advertService.getAll();
    if (adverts) {
      const validAdverts = adverts.filter((x) =>
        isFuture(formatToDate(x.validUntil))
      );
      const ordered = validAdverts.sort((a, b) =>
        formatToDate(b.createdAt) < formatToDate(a.createdAt) ? 1 : -1
      );

      const grouped = _.groupBy(ordered, (advert) => advert.type);

      return grouped;
    }
  }, [advertService]);

  useEffect(() => {
    getMostVotedSurveys().then((data) => setTopFive(data));
    countSurveysPerScope().then((data) => setSurveysPerScope(data));
    getUsersProgression().then((data) => setUsersPerMonth(data));
    getAdvertsAmountPerMonth().then((data) => setAds(data));
    getAdvertsPerType().then((data) => setAdsPerType(data));
    getExpiredSurveys();
  }, [
    getMostVotedSurveys,
    countSurveysPerScope,
    getUsersProgression,
    getExpiredSurveys,
    getAdvertsAmountPerMonth,
    getAdvertsPerType,
  ]);

  return (
    <>
      <Sidebar />
      <Header title="Dashboard" />
      <Main>
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#404040",
                height: "100%",
                maxHeight: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">
                Enquetes mais votadas (Top 5)
              </Typography>
              <Spacer size={10} />

              {topFive && (
                <Bar
                  data={{
                    labels: topFive.map((_, i) => "Enquete " + (i + 1)),
                    datasets: [
                      {
                        data: topFive.map((item) => item.votes),
                        backgroundColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                          "rgba(75, 192, 192, 1)",
                          "rgba(153, 102, 255, 1)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                          "rgba(75, 192, 192, 1)",
                          "rgba(153, 102, 255, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          title: (item) => {
                            const data = topFive[item[0].dataIndex];
                            return data.survey.description;
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </Paper>
          </Grid>

          <Grid xs={6} item>
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#404040",
                height: "100%",
                maxHeight: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">Enquetes por escopo</Typography>
              <Spacer size={10} />

              {surveysPerScope && (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Pie
                    data={{
                      labels: ["Nacional", "Estadual", "Municipal"],
                      datasets: [
                        {
                          label: "# of Votes",
                          data: surveysPerScope,
                          backgroundColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                          ],
                          borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                    style={{
                      maxWidth: "400px",
                      maxHeight: "400px",
                    }}
                  />
                </div>
              )}
            </Paper>
          </Grid>

          <Grid xs={12} item>
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#404040",
                height: "100%",
                maxHeight: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">
                Progressão de usuários
              </Typography>
              <Spacer size={10} />

              <Line
                data={{
                  labels: _.keys(usersPerMonth),
                  datasets: [
                    {
                      label: "Usuários cadastrados",
                      data: _.map(usersPerMonth, (v, _) => v.length),
                      fill: false,
                      backgroundColor: "rgb(255, 99, 132)",
                      borderColor: "rgba(255, 99, 132, 0.2)",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                style={{ maxHeight: "400px" }}
              />
            </Paper>
          </Grid>

          <Grid xs={8} item>
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#404040",
                height: "100%",
                maxHeight: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">
                Receita com anúncios por mês
              </Typography>
              <Spacer size={10} />

              <Line
                data={{
                  labels: _.keys(ads),
                  datasets: [
                    {
                      label: "Receita no mês",
                      data: _.map(ads, (v, _) =>
                        v.reduce((a, b) => {
                          return a + b.price!;
                        }, 0)
                      ),
                      fill: false,
                      backgroundColor: "rgb(255, 99, 132)",
                      borderColor: "rgba(255, 99, 132, 0.2)",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                style={{ maxHeight: "400px" }}
              />
            </Paper>
          </Grid>

          <Grid xs={4} item>
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#404040",
                height: "100%",
                maxHeight: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">Anúncios por tipo</Typography>
              <Spacer size={10} />

              <Doughnut
                data={{
                  labels: _.keys(adsPerType),
                  datasets: [
                    {
                      data: _.map(adsPerType, (v, _) => v.length),
                      backgroundColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                style={{ maxHeight: "400px" }}
              />
            </Paper>
          </Grid>

          <Grid xs={12} item>
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#404040",
                height: "100%",
                maxHeight: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">Enquetes expiradas</Typography>
              <Spacer size={10} />

              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Escopo</TableCell>
                    <TableCell align="right">Votos</TableCell>
                    <TableCell align="right">Categoria</TableCell>
                    <TableCell align="right">Valido até</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {surveys &&
                    surveys.map((survey, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          height: 50,
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {checkSurveyIsExpired(survey.validUntil) && (
                            <>
                              <Chip
                                label="Expirada"
                                color="error"
                                size="small"
                              />
                              <Spacer size={5} horizontal />
                            </>
                          )}
                          {survey.description}
                        </TableCell>
                        <TableCell align="right">{survey.scope}</TableCell>
                        <TableCell align="right">
                          {calculateTotal(survey)}
                        </TableCell>
                        <TableCell align="right">
                          <Chip label={survey.category} size="medium" />
                        </TableCell>
                        <TableCell align="right">
                          {formatDate(survey.validUntil)}
                        </TableCell>
                        <TableCell align="right">
                          <ButtonGroup>
                            <IconButton>
                              <Visibility
                                onClick={() => history.push("/surveys")}
                                sx={{ fontSize: 20, color: "#ffffff" }}
                              />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default Dashboard;
