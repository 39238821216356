import { collection, getDocs, query } from "@firebase/firestore";
import { Advert } from "../models/advert";
import { store } from "./firebase";

class AdvertService {
  async getAll(): Promise<Advert[] | undefined> {
    const advertsRef = collection(store, "adverts");

    const q = query(advertsRef);

    const querySnapshot = await getDocs(q);
    const adverts = querySnapshot.docs.map((doc) => {
      const id = doc.id;
      const advert = doc.data() as Advert;

      return { ...advert, id };
    });

    return adverts;
  }

  async create(): Promise<void> {}

  async update(): Promise<void> {}

  async remove(): Promise<void> {}
}

export default AdvertService;
