import React, { useEffect, useMemo, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";

import { Advert } from "../../../domain/models/advert";
import AdvertService from "../../../domain/services/AdvertService";
import { formatDate, formatToDate } from "../../utils/formatters/date";

import Delete from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import { Timestamp } from "@firebase/firestore";
import { isPast } from "date-fns";
import Spacer from "../../components/Spacer";
import ViewAdvertModal from "./ViewAdvertModal";
import resolvePayment from "../../utils/resolvers/payment_resolver";

const Adverts: React.FC = () => {
  const [adverts, setAdverts] = useState<Advert[]>();
  const [activeAdvert, setActiveAdvert] = useState<Advert>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const advertService = useMemo(() => new AdvertService(), []);

  useEffect(() => {
    advertService.getAll().then((data) => {
      setAdverts(data);
      setIsLoading(false);
    });
  }, [advertService]);

  const checkSurveyIsExpired = (timestamp: Timestamp): boolean => {
    const date = formatToDate(timestamp);
    return isPast(date);
  };

  const handleToggleModal = (advert?: Advert) => {
    if (isModalOpen) {
      setActiveAdvert(undefined);
    } else {
      setActiveAdvert(advert);
    }

    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Sidebar />
      <Header title="Anúncios" />

      {activeAdvert && (
        <ViewAdvertModal
          data={activeAdvert!}
          open={isModalOpen}
          onClose={handleToggleModal}
        />
      )}

      <Main>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">Tipo</TableCell>
                <TableCell align="left">Escopo</TableCell>
                <TableCell align="left">Situação</TableCell>
                <TableCell align="left">Pagamento</TableCell>
                <TableCell align="left">Válido até</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    align="center"
                    sx={{ height: 100 }}
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                adverts &&
                adverts.map((advert, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      height: 50,
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">
                      <Chip label={advert.type} size="medium" />
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{advert.scope}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Chip
                        label={advert.isActive ? "ativo" : "inativo"}
                        color={advert.isActive ? "success" : "error"}
                        size="medium"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Chip
                        label={resolvePayment(advert.paymentStatus).text}
                        color={resolvePayment(advert.paymentStatus).color}
                        size="medium"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Typography sx={{ display: "flex" }}>
                        {formatDate(advert.validUntil)}
                        {checkSurveyIsExpired(advert.validUntil) && (
                          <>
                            <Spacer size={5} horizontal />
                            <Chip label="Expirada" color="error" size="small" />
                          </>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup>
                        <IconButton onClick={() => handleToggleModal(advert)}>
                          <Visibility sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton>
                          <Delete sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Main>
    </>
  );
};

export default Adverts;
