import capitalsData from "../data/capitais.json";
import smallCities from "../data/cidades_menos_100k.json";
import bigCities from "../data/cidades_acima_100k.json";

interface City {
  code: string;
  city: string;
  state: string;
  population: number;
}

export function useCapitals() {
  const capitals: City[] = capitalsData.data;

  return capitals;
}

export function useSmallCities() {
  const cities: City[] = smallCities.data;

  return cities;
}

export function useBigCities() {
  const cities: City[] = bigCities.data;

  return cities;
}
