import React from "react";

const Main: React.FC = ({ children }) => {
  return (
    <>
      <main style={{ marginLeft: 200, padding: "10px 20px" }}>{children}</main>
    </>
  );
};

export default Main;
