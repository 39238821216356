import { initializeApp } from "@firebase/app";

import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getAnalytics } from "@firebase/analytics";
import { getAuth } from "@firebase/auth";

import firebaseConfig from "../config/firebaseConfig";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const store = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { app, analytics, auth, store, storage };
