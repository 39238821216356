import React, { createContext } from "react";
import useStorage from "../utils/hooks/useStorage";
import Admin from "../../domain/models/admin";

type AuthContextProps = {
  user?: Admin;
  setUser: (user: Admin) => void;
};

export const AuthContext = createContext<AuthContextProps>({
  setUser: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useStorage("__user");

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
