import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import DashboardOutlined from "@material-ui/icons/DashboardOutlined";
import AssignmentOutlined from "@material-ui/icons/AssignmentOutlined";
import ClassOutlined from "@material-ui/icons/ClassOutlined";
import AttachMoneyOutlined from "@material-ui/icons/AttachMoneyOutlined";
import Storefront from "@material-ui/icons/Storefront";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";

import { useHistory } from "react-router";

import logo from "../../../../assets/images/logos/icon.png";
import Spacer from "../../Spacer";

const Sidebar: React.FC = () => {
  const history = useHistory();

  const items = [
    { title: "Dashboard", icon: <DashboardOutlined />, route: "/dashboard" },
    { title: "Categorias", icon: <ClassOutlined />, route: "/categories" },
    { title: "Enquetes", icon: <AssignmentOutlined />, route: "/surveys" },
    { title: "Sugestões", icon: <AssignmentOutlined />, route: "/suggestions" },
    { title: "Anúncios", icon: <AttachMoneyOutlined />, route: "/adverts" },
    { title: "Loja", icon: <Storefront />, route: "/store" },
    { title: "Pedidos", icon: <ShoppingCartOutlined />, route: "/orders" },
  ];

  const handleNavigate = (route: string) => {
    history.push(route);
  };

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        open
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginY: 3,
          }}
        >
          <img src={logo} alt="logo" width={40} />
          <Spacer size={10} horizontal />
          <Typography variant="h5">MyPrefs</Typography>
        </Box>
        <List>
          {items.map((item, i) => (
            <ListItem key={i} button onClick={() => handleNavigate(item.route)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.title}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
