import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Spacer from "../../../components/Spacer";
import MenuItem from "@material-ui/core/MenuItem";

import Add from "@material-ui/icons/Add";
import { useFormik } from "formik";
import Product from "../../../../domain/models/product";
import Card from "@material-ui/core/Card";
import { styled } from "@mui/material/styles";

type ProductFormModalProps = {
  open: boolean;
  product?: Product;
  onClose?: () => void;
  onSubmit: (product: Product, files: File[], editing: boolean) => void;
};

interface NewProduct {
  name: string;
  description: string;
  pictures?: string[];
  price: number;
  recommended: boolean;
}

const Input = styled("input")({
  display: "none",
});

const ProductFormModal: React.FC<ProductFormModalProps> = ({
  product,
  open,
  onClose,
  onSubmit,
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const formik = useFormik<NewProduct>({
    initialValues: {
      name: product?.name || "",
      description: product?.description || "",
      price: product?.price || 0,
      recommended: product?.recommended || false,
    },
    onSubmit: (values) => {
      const newProduct = {
        ...values,
        mainPicture: "",
      };

      onSubmit(newProduct, files, !!product);
    },
  });

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      const images = Array.from<File>(event.target.files);
      setFiles([...files, ...images]);
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box>
            <Typography variant="h6">
              {product ? "Editar" : "Adicionar"} produto
            </Typography>
            <Spacer size={20} />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Nome</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  fullWidth
                  hiddenLabel
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle2">Preço</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="price"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  fullWidth
                  hiddenLabel
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Descrição</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  fullWidth
                  hiddenLabel
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle2">Recomendado</Typography>
                <TextField
                  size="small"
                  name="recommended"
                  onChange={formik.handleChange}
                  value={formik.values.recommended}
                  defaultValue=""
                  select
                  hiddenLabel
                  fullWidth
                >
                  <MenuItem value="">Selecione uma categoria</MenuItem>
                  <MenuItem value="true">SIM</MenuItem>
                  <MenuItem value="false">NÃO</MenuItem>
                </TextField>
              </Grid>

              <Box sx={{ width: "100%", padding: 2 }}>
                <Typography variant="h6">Imagens</Typography>
                <Spacer size={10} />

                <Grid xs={12} spacing={1} container>
                  {product &&
                    [product!.mainPicture, ...(product!.pictures! ?? [])].map(
                      (item, i) => (
                        <Grid key={i} xs={2}>
                          <img src={item} width={100} alt="Product" />
                        </Grid>
                      )
                    )}
                  {files &&
                    files.map((file, i) => (
                      <Grid key={i} xs={2}>
                        <img
                          src={URL.createObjectURL(file)}
                          width={100}
                          alt="Product"
                        />
                      </Grid>
                    ))}
                  <Grid xs={4}>
                    <label htmlFor="images-button">
                      <Input
                        accept="image/*"
                        id="images-button"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Card sx={{ width: "100px", p: 2, cursor: "pointer" }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Add />
                          <Typography sx={{ textAlign: "center" }}>
                            Adicionar imagem
                          </Typography>
                        </Box>
                      </Card>
                    </label>
                  </Grid>
                </Grid>
              </Box>

              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                item
              >
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancelar
                </Button>
                <Spacer size={10} horizontal />
                <Button variant="contained" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ProductFormModal;
