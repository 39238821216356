import React, { useEffect, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Survey from "../../../domain/models/survey";
import SurveyService from "../../../domain/services/SurveyService";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";

import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";
import Check from "@material-ui/icons/Check";

import { formatDate } from "../../utils/formatters/date";
import Swal from "sweetalert2";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ViewSuggestionModal from "./ViewSuggestionModal";
import SuggestionFormModal from "./SuggestionFormModal";

const Suggestions: React.FC = () => {
  const [surveys, setSurveys] = useState<Survey[]>();
  const [activeSurvey, setActiveSurvey] = useState<Survey>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const surveyService = useMemo(() => new SurveyService(), []);

  useEffect(() => {
    surveyService.getAllSuggested().then((data) => {
      setSurveys(data);
      setIsLoading(false);
    });
  }, [surveyService]);

  const calculateTotal = (survey: Survey): number => {
    const total = survey.options.reduce((a, b) => {
      return a + b.votes;
    }, 0);

    return total;
  };

  const handleToggleModal = (survey?: Survey) => {
    if (isModalOpen) {
      setActiveSurvey(undefined);
    } else {
      setActiveSurvey(survey);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleToggleFormModal = (survey?: Survey) => {
    if (isFormModalOpen) {
      setActiveSurvey(undefined);
    } else {
      setActiveSurvey(survey);
    }

    setIsFormModalOpen(!isFormModalOpen);
  };

  const handleOnSubmit = (survey: Survey, editing: boolean) => {
    let statement: Promise<void>;

    if (editing) {
      statement = surveyService.update(survey);
    } else {
      statement = surveyService.create(survey);
    }

    statement
      .then(async () => {
        setIsFormModalOpen(false);
        await Swal.fire(
          "",
          `Enquete ${editing ? "editada" : "criada"} com sucesso.`,
          "success"
        );

        setIsLoading(true);
        surveyService.getAllSuggested().then((data) => {
          setSurveys(data);
          setIsLoading(false);
        });
      })
      .catch(async (err) => {
        setIsFormModalOpen(false);
        console.error(err);
        await Swal.fire(
          "Oops",
          `Não foi possível ${
            editing ? "editar" : "criar"
          } a sugestão, por favor tente novamente.`,
          "error"
        );
      });
  };

  const handleToggleDialog = (survey?: Survey) => {
    if (isDialogOpen) {
      setActiveSurvey(undefined);
    } else {
      setActiveSurvey(survey);
    }

    setIsDialogOpen(!isDialogOpen);
  };

  const handleToggleApproveDialog = (survey?: Survey) => {
    if (isApproveDialogOpen) {
      setActiveSurvey(undefined);
    } else {
      setActiveSurvey(survey);
    }

    setIsApproveDialogOpen(!isApproveDialogOpen);
  };

  const handleRemoveSurvey = () => {
    surveyService
      .remove(activeSurvey!.id!)
      .then(async () => {
        setIsDialogOpen(false);
        await Swal.fire("", `Enquete excluída com sucesso.`, "success");

        setIsLoading(true);
        surveyService.getAllSuggested().then((data) => {
          setSurveys(data);
          setIsLoading(false);
        });
      })
      .catch(async (err) => {
        setIsDialogOpen(false);
        console.error(err);
        await Swal.fire(
          "Oops",
          `Não foi possível excluir a sugestão, por favor tente novamente.`,
          "error"
        );
      });
  };

  const handleApproveSurvey = () => {
    surveyService
      .approve(activeSurvey!.id!)
      .then(async () => {
        setIsApproveDialogOpen(false);
        await Swal.fire("", `Enquete aprovada com sucesso.`, "success");

        setIsLoading(true);
        surveyService.getAllSuggested().then((data) => {
          setSurveys(data);
          setIsLoading(false);
        });
      })
      .catch(async (err) => {
        setIsApproveDialogOpen(false);
        console.error(err);
        await Swal.fire(
          "Oops",
          `Não foi possível aprovar a sugestão, por favor tente novamente.`,
          "error"
        );
      });
  };

  return (
    <>
      <Sidebar />
      <Header title="Sugestões" />

      {activeSurvey && (
        <ViewSuggestionModal
          data={activeSurvey!}
          open={isModalOpen}
          onClose={handleToggleModal}
        />
      )}

      {isFormModalOpen && (
        <SuggestionFormModal
          open={isFormModalOpen}
          onClose={handleToggleFormModal}
          onSubmit={handleOnSubmit}
          survey={activeSurvey}
        />
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => handleToggleDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Excluir sugestão</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja excluir a sugestão selecionada? Ao
            excluir os dados não poderão ser recuperados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "#fff" }} onClick={() => handleToggleDialog()}>
            Cancelar
          </Button>
          <Button sx={{ color: "#fff" }} onClick={handleRemoveSurvey} autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isApproveDialogOpen}
        onClose={() => handleToggleApproveDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Aprovar sugestão</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja aprovar a sugestão selecionada? Dada a aprovação, a enquete será disponibilizada instantaneamente para os usuários.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "#fff" }} onClick={() => handleToggleApproveDialog()}>
            Cancelar
          </Button>
          <Button sx={{ color: "#fff" }} onClick={handleApproveSurvey} autoFocus>
            Aprovar
          </Button>
        </DialogActions>
      </Dialog>

      <Main>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell align="right">Escopo</TableCell>
                <TableCell align="right">Votos</TableCell>
                <TableCell align="right">Categoria</TableCell>
                <TableCell align="right">Sugerido em</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    scope="row"
                    align="center"
                    sx={{ height: 100 }}
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                surveys &&
                surveys.map((survey, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      height: 50,
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {survey.description}
                    </TableCell>
                    <TableCell align="right">{survey.scope}</TableCell>
                    <TableCell align="right">
                      {calculateTotal(survey)}
                    </TableCell>
                    <TableCell align="right">
                      <Chip label={survey.category || "A DEFINIR"} size="medium" />
                    </TableCell>
                    <TableCell align="right">
                      {formatDate(survey.createdAt)}
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup>
                        <IconButton onClick={() => handleToggleApproveDialog(survey)}>
                          <Check sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton onClick={() => handleToggleModal(survey)}>
                          <Visibility sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleToggleFormModal(survey)}
                        >
                          <Edit sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                        <IconButton onClick={() => handleToggleDialog(survey)}>
                          <Delete sx={{ fontSize: 20, color: "#ffffff" }} />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Main>
    </>
  );
};

export default Suggestions;
