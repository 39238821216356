import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Product from "../../../../domain/models/product";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type ViewProductModalProps = {
  data: Product;
  open: boolean;
  onClose?: () => void;
};

const ViewProductModal: React.FC<ViewProductModalProps> = ({
  data,
  open = false,
  onClose,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid spacing={2} container>
            <Grid item xs={2}>
              <Typography variant="subtitle2">Imagem do produto</Typography>
              <img
                src={data.mainPicture!}
                alt="imagem do produto"
                width={100}
              />
            </Grid>

            <Grid item xs={8}>
              <Typography variant="subtitle2">Nome do produto</Typography>
              <Typography variant="body1">{data.name ?? "--"}</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Preço</Typography>
              <Typography variant="body1">{data.price ?? "--"}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2">Descrição</Typography>
              <Typography variant="body1">
                {data.description ?? "--"}
              </Typography>
            </Grid>

            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              item
            >
              <Button variant="contained" onClick={handleClose}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ViewProductModal;
