import React, { useEffect, useMemo, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Spacer from "../../../components/Spacer";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";

import Delete from "@material-ui/icons/Delete";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Stack from "@material-ui/core/Stack";
import Tooltip from "@material-ui/core/Tooltip";
import { useFormik } from "formik";
import { addMonths, format } from "date-fns";
import CategoryService from "../../../../domain/services/CategoryService";
import Category from "../../../../domain/models/category";
import Survey from "../../../../domain/models/survey";
import { Timestamp } from "@firebase/firestore";
import { formatToDate } from "../../../utils/formatters/date";

type SuggestionFormModalProps = {
  open: boolean;
  survey?: Survey;
  onClose?: () => void;
  onSubmit: (survey: Survey, editing: boolean) => void;
};

interface NewSurvey {
  description: string;
  scope: string;
  state: string;
  city: string;
  group: string;
  category: string;
  maxCheck: number;
  knowMore: string;
  validUntil: Date;
  options: {
    description: string;
    isJoker: boolean;
  }[];
}

const SuggestionFormModal: React.FC<SuggestionFormModalProps> = ({
  survey,
  open,
  onClose,
  onSubmit,
}) => {
  const categoryService = useMemo(() => new CategoryService(), []);

  const [categories, setCategories] = useState<Category[]>();

  useEffect(() => {
    categoryService.getAll().then((data) => {
      setCategories(data);
    });
  }, [categoryService]);

  const formik = useFormik<NewSurvey>({
    initialValues: {
      description: survey?.description || "",
      scope: survey?.scope || "nacional",
      state: survey?.state || "",
      city: survey?.city || "",
      group: survey?.group || "",
      category: survey?.category || "",
      maxCheck: survey?.maxCheck || 1,
      knowMore: survey?.knowMore || "",
      validUntil: survey?.validUntil
        ? formatToDate(survey.validUntil)
        : addMonths(new Date(), 1),
      options: survey?.options || [
        {
          description: "",
          isJoker: false,
        },
      ],
    },
    onSubmit: (values) => {
      const options = values.options.map((option) => ({
        description: option.description,
        isJoker: option.isJoker,
        votes: 1,
        eyeLink: null,
      }));

      const newSurvey: Survey = {
        ...values,
        id: survey?.id,
        hasMultiple: values.maxCheck > 1,
        knowMore: values.knowMore || null,
        sponsored: false,
        suggested: true,
        options,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        validUntil: Timestamp.fromDate(values.validUntil),
      };

      onSubmit(newSurvey, !!survey);
    },
  });

  const handleClose = () => {
    onClose?.();
  };

  const handleAddOption = () => {
    const { options } = formik.values;

    formik.setFieldValue("options", [
      ...options,
      {
        description: "",
        isJoker: false,
      },
    ]);
  };

  const handleRemoveOption = (option: any) => {
    const { options } = formik.values;
    const filtered = options.filter((x) => x !== option);

    formik.setFieldValue("options", filtered);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box>
            <Typography variant="h6">
              {survey ? "Editar" : "Adicionar"} enquete
            </Typography>
            <Spacer size={20} />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Descrição</Typography>
                <TextField
                size="small"
                  variant="outlined"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  fullWidth
                  hiddenLabel
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">Escopo</Typography>
                <TextField
                size="small"
                  name="scope"
                  onChange={formik.handleChange}
                  value={formik.values.scope}
                  select
                  hiddenLabel
                  fullWidth
                >
                  <MenuItem value="nacional">Nacional</MenuItem>
                  <MenuItem value="estadual">Estadual</MenuItem>
                  <MenuItem value="municipal">Municipal</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">Estado</Typography>
                <TextField
                size="small"
                  name="state"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  fullWidth
                  hiddenLabel
                  disabled={formik.values.scope !== "estadual"}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">Cidade</Typography>
                <TextField
                size="small"
                  name="city"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  fullWidth
                  hiddenLabel
                  disabled={formik.values.scope !== "municipal"}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">Grupo</Typography>
                <TextField
                size="small"
                  name="group"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.group}
                  fullWidth
                  hiddenLabel
                  disabled={formik.values.scope !== "municipal"}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle2">Categoria</Typography>
                <TextField
                size="small"
                  name="category"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  defaultValue=""
                  select
                  hiddenLabel
                  fullWidth
                >
                  <MenuItem value="">Selecione uma categoria</MenuItem>
                  {categories &&
                    categories.map((category, i) => (
                      <MenuItem
                        value={category.description.toUpperCase()}
                        key={i}
                      >
                        {category.description.toUpperCase()}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Box sx={{ width: "100%", padding: 2 }}>
                <Typography variant="h6">Opções</Typography>
                <List sx={{ maxHeight: 180, overflowY: "auto" }}>
                  {formik.values.options.map((option, i) => (
                    <ListItem key={i} sx={{ paddingY: 1 }} disablePadding>
                      <Grid xs={12} spacing={2} container>
                        <Grid item xs={9}>
                          <Typography variant="subtitle2">Descrição</Typography>
                          <TextField
                          size="small"
                            variant="outlined"
                            name={`options[${i}].description`}
                            onChange={formik.handleChange}
                            value={formik.values.options[i].description}
                            fullWidth
                            hiddenLabel
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Typography variant="subtitle2">Joker</Typography>
                          <TextField
                          size="small"
                            select
                            hiddenLabel
                            fullWidth
                            name={`options[${i}].isJoker`}
                            onChange={formik.handleChange}
                            value={formik.values.options[i].isJoker}
                          >
                            <MenuItem value="true">Sim</MenuItem>
                            <MenuItem value="false">Não</MenuItem>
                          </TextField>
                        </Grid>

                        {i !== 0 && (
                          <Grid item xs={1}>
                            <Box
                              flexGrow={1}
                              flex={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ height: "100%" }}
                            >
                              <IconButton
                                size="large"
                                onClick={() => handleRemoveOption(option)}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </ListItem>
                  ))}
                </List>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  sx={{ color: "#ffffff" }}
                >
                  <Button
                    color="inherit"
                    variant="outlined"
                    onClick={handleAddOption}
                  >
                    Adicionar opção
                  </Button>
                </Stack>
              </Box>

              <Grid item xs={3}>
                <Typography variant="subtitle2">Válida até</Typography>
                <TextField
                size="small"
                  variant="outlined"
                  name="validUntil"
                  type="date"
                  onChange={formik.handleChange}
                  value={format(formik.values.validUntil, "yyyy-MM-dd")}
                  fullWidth
                  hiddenLabel
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2">Saiba mais</Typography>
                <TextField
                size="small"
                  variant="outlined"
                  name="knowMore"
                  onChange={formik.handleChange}
                  value={formik.values.knowMore}
                  fullWidth
                  hiddenLabel
                />
              </Grid>

              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Quantidade de respostas
                  <Spacer size={5} horizontal />
                  <Tooltip title="Quantidade de respostas que o usuário pode marcar ao mesmo tempo">
                    <HelpOutline sx={{ fontSize: 16 }} />
                  </Tooltip>
                </Typography>
                <TextField
                size="small"
                  variant="outlined"
                  name="maxCheck"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.maxCheck}
                  fullWidth
                  hiddenLabel
                />
              </Grid>

              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                item
              >
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancelar
                </Button>
                <Spacer size={10} horizontal />
                <Button variant="contained" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default SuggestionFormModal;
